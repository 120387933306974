<template>
    <form
        class="form"
        novalidate="novalidate"
        id="st_forgot_form"
        ref="st_forgot_form"
    >
        <div class="form-group last">
            <label>
                {{ $t('AUTH.INPUT.EMAIL') }} *</label
            >
            <div id="password-input-group" label="" label-for="password-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="text"
                    name="email"
                    ref="email"
                />
            </div>
        </div>
        <div class="form-group d-flex flex-row pb-lg-0">
            <div class="col-6 pl-0">
                <button
                    id="st_submit_button"
                    ref="st_submit_button"
                    class="btn btn-primary font-size-h4 w-100"
                >
                    {{ $t('AUTH.FORGOT.BUTTON') }}
                </button>
            </div>
            <div class="col-6 pr-0">
                <router-link :to="{ path: '/auth/signin' }">
                    <button
                        type="button"
                        id="st_cancel_button"
                        class="btn btn-light-primary font-size-h4 w-100"
                    >
                        {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
                    </button>
                </router-link>
            </div>
        </div>
    </form>
</template>
<script>
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

    // FormValidation plugins
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
    import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

    import KTUtil from '@/assets/js/components/util';

    import { mapActions } from 'vuex';

    import { SEND_PASSWORD_RESET_EMAIL } from '@/modules/auth/auth-store';

    export default {
        name: 'ForgotPasswordForm',
        data() {
            return {
                email: ''
            };
        },
        methods: {
            ...mapActions({
                sendPasswordResetEmail: `auth/${SEND_PASSWORD_RESET_EMAIL}`
            })
        },
        mounted() {
            const forgot_form = KTUtil.getById('st_forgot_form');

            this.fv = formValidation(forgot_form, {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            emailAddress: {
                                message: this.$t('AUTH.VALIDATION.EMAIL')
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });

            this.fv.on('core.form.valid', () => {
                const email = this.$refs.email.value;

                // set spinner to submit button
                const submitButton = this.$refs['st_submit_button'];
                submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
                // dummy delay
                setTimeout(() => {

                    this.sendPasswordResetEmail({ email: email })
                        .then(() => this.$router.push({ name: 'signin' }));

                    submitButton.classList.remove(
                        'spinner',
                        'spinner-light',
                        'spinner-right'
                    );
                }, 2000);
            });
        }
    };
</script>
